@import url(https://fonts.googleapis.com/css?family=Roboto:400,500,700);
html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.data-row {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  white-space: nowrap;
  font-size: 0.875rem;
  line-height: 1.25rem;
  --text-opacity: 1;
  color: #64748b;
  color: rgba(100, 116, 139, var(--text-opacity));
}

.card {
  padding: 1.5rem;
  --bg-opacity: 1;
  background-color: #ffffff;
  background-color: rgba(255, 255, 255, var(--bg-opacity));
  border-radius: 0.375rem;
  border-width: 1px;
  --border-opacity: 1;
  border-color: #e5e7eb;
  border-color: rgba(229, 231, 235, var(--border-opacity));
  margin-bottom: 0.5rem;
}

.h3 {
  font-size: 1.125rem;
  line-height: 1.5rem;
  font-weight: 600;
}

.sidebar{
  background: #252f40;
  color: #fff;
  height: 200vh;
  width: 300px;
  transition: all 0.5s;
}

.top_section{
  display: flex;
  align-items:center;
  padding:20px 15px;
}

.logo{
  font-size: 30px;
}

.bars{
  display: flex;
  font-size: 25px;
  margin-left: 115px;
  color: #fff;
}

